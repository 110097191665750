import Tooltip from "@mui/material/Tooltip";
import React from "react";
// import tooltipStyles from "../Inputs/styles/tooltip.module.css";
import "../../../../Tooltip/styles/tooltip.css";
import "./styles/color-legend.css";

const ColorsLegend = ({ requestStatus }) => {
  const baseColors = {
    Completed: "rgb(22, 209, 40)",
    Incomplete: "rgb(204, 5, 5)",
    "Current Slide": "rgb(25, 166, 237)",
  };

  const revisionColors = {
    Completed: "rgb(22, 209, 40)",
    "Suggested Revisions": "rgb(255, 0, 0)",
    "Current Slide": "rgb(25, 166, 237)",
  };

  const baseColorIds = {
    Completed: "completed-dot",
    Incomplete: "incomplete-dot",
    "Current Slide": "current-slide-dot",
  };

  const revisionColorIds = {
    "Suggested Revisions": "revisions-dot",
    Completed: "completed-dot-revise",
    "Current Slide": "current-slide-dot",
  };

  const colorsMessage = {
    Completed: "All required fields have been filled out for this slide",
    Incomplete: "Required fields have not been filled out for this slide",
    "Not Started": "No fields have been filled out for this slide",
    "Suggested Revisions":
      "The incomplete/invalid fields for this slide have not been updated",
    "Current Slide": "This is the current slide",
  };
  return (
    <div className="colors-legend-container">
      {/*
      If requestStatus !== "RV" then use base colors to create a legend of colors
      - The legend should have a name which is the key of the color object, and a circle with the color of the value
      */}
      {requestStatus !== "RV" ? (
        // The legend should look like this:
        // Completed : O (colored rgb(22, 209, 40)), etc.
        // There should be 2 per row (each object has only 4 keys)
        <div className="colors-legend">
          {Object.keys(baseColors).map((key, index) => {
            return (
              <Tooltip
                key={`${key}-tooltip`}
                id="color-legend-tooltip"
                title={colorsMessage[key]}
                placement={
                  key === "Incomplete" || key === "Completed" ? "top" : "bottom"
                }
                arrow
                classes={{
                  tooltip: "color-legend-tooltip",
                  popper: "color-legend-popper",
                  arrow: "color-legend-arrow",
                  tooltipPlacementTop: "tooltip-top",
                  tooltipPlacementBottom: "tooltip-bottom",
                }}
              >
                <div id={baseColorIds[key]} className="colors" key={index}>
                  <span className={"color-label"}>{`${key}: `}</span>
                  <div
                    className="color-circle"
                    style={{ backgroundColor: baseColors[key] }}
                  ></div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      ) : (
        <div className="colors-legend revise">
          {Object.keys(revisionColors).map((key, index) => {
            return (
              <Tooltip
                key={`${key}-tooltip`}
                title={colorsMessage[key]}
                placement={key !== "Current Slide" ? "top" : "bottom"}
                arrow
                id="color-legend-tooltip"
                classes={{
                  tooltip: "color-legend-tooltip",
                  popper: "color-legend-popper",
                  arrow: "color-legend-arrow",
                  tooltipPlacementTop: "tooltip-top",
                  tooltipPlacementBottom: "tooltip-bottom",
                }}
              >
                <div
                  id={revisionColorIds[key]}
                  className={"colors"}
                  key={index}
                >
                  <span className={"color-label"}>{`${key}: `}</span>
                  <div
                    className="color-circle"
                    style={{
                      backgroundColor: revisionColors[key],
                    }}
                  ></div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ColorsLegend;
