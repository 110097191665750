import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import useInvalidInputs from "../Slides/Hooks/useInvalidInputs";
import { FormContext } from "../context/FormProvider";
import "../styles/form-container.css";
import ColorsLegend from "./ColorsLegend";
import "./styles/timeline.css";

const TimelineContainer = ({
  slideStatus,
  setSlideStatus,
  invalidSlides,
  setInvalidSlideNumbers,
}) => {
  const {
    pageNumber,
    setPageNumber,
    requestStatus,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
  } = useContext(FormContext);

  const { clientId, projectId, queryClient } = useContext(DashboardContext);

  const { data: invalidInputsData, isFetched: invalidInputsFetched } =
    useInvalidInputs(clientId, projectId, requestStatus);

  useEffect(() => {
    if (!invalidInputsFetched) return;
    console.log("invalidInputsData: ", invalidInputsData);
  }, [invalidInputsFetched]);

  const [invalidSlideValues, setInvalidSlideValues] = useState({});

  const handleTimelineClick = async (e, newPageNumber) => {
    console.log("pageNumber: ", newPageNumber);
    if (pageNumber !== newPageNumber) {
      setPageNumber(+newPageNumber);
    }
  };

  useEffect(() => {
    if (!projectId) return;
    if (
      invalidInputsFetched &&
      invalidInputsData &&
      invalidInputsData.length > 0
    ) {
      const invalidSlideNumbers = invalidInputsData.map(
        (invalidInput) => +invalidInput.slide,
      );
      // console.log("INVALID SLIDE NUMBERS: ", invalidSlideNumbers);
      let uniqueInvalidSlideNumbers = [...new Set(invalidSlideNumbers)].sort(
        (a, b) => a - b,
      );
      setInvalidSlideNumbers(() => uniqueInvalidSlideNumbers);
      // Iterate over invalidInputsData.data array and for each invalidInput object, create an object like this:
      // {slide#: {name: initialValue}, etc. for each slide that contains an invalid input}
      // Do this for each slide
      let invalidSlideValues = {};
      for (let i = 0; i < invalidInputsData.length; i++) {
        let invalidInput = invalidInputsData[i];
        let slide = invalidInput.slide;
        let name = invalidInput.name;
        let value = invalidInput.initialValue;
        if (value === "true") {
          value = true;
        }
        if (value === "false") {
          value = false;
        }
        if (!invalidSlideValues[`slide${slide}`]) {
          invalidSlideValues[`slide${slide}`] = {};
        }
        invalidSlideValues[`slide${slide}`][name] = value;
      }

      setInvalidSlideValues(() => invalidSlideValues);
    }
  }, [invalidInputsFetched, invalidInputsData]);

  const checkInvalidSlideStatus = (slideNumber, slideState, invalidInputs) => {
    if (!invalidSlides || !invalidSlides.includes(slideNumber)) {
      return;
    }

    let slideStr = `slide${slideNumber}`;
    let style = `editedSlide`;

    for (const [key, value] of Object.entries(slideState)) {
      if (!invalidInputs[slideStr]) continue;
      if (
        invalidInputs[slideStr][key] &&
        value === invalidInputs[slideStr][key]
      ) {
        style = `reviseSlide`;
        break;
      }
    }

    return style;
  };

  // useEffect(() => console.log(slideStatus), [slideStatus]);

  return (requestStatus === "RV" && invalidInputsFetched) ||
    requestStatus !== "RV" ? (
    <div className="timeline-container">
      <div className="timeline">
        <h2 id="timeline-header">{"Form Navigation"}</h2>
        <hr id="navigation-hr" />
        {/* <ColorsLegend requestStatus={requestStatus} /> */}
        <Timeline
          position="alternate"
          className={"timelineComponent"}
          classes={{
            root: "timelineRoot",
          }}
        >
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 1)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(1)
                    ? checkInvalidSlideStatus(1, slide1, invalidSlideValues)
                    : pageNumber !== 1 && slideStatus.slide1 === "complete"
                      ? "complete"
                      : pageNumber === 1
                        ? "activeDot"
                        : "incomplete"
                }
                variant={
                  pageNumber === 1 || slideStatus.slide1 === "complete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(1)
                    ? checkInvalidSlideStatus(1, slide1, invalidSlideValues)
                    : slideStatus.slide1 === "complete"
                      ? `completedLine`
                      : slideStatus.slide1 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(1)
                  ? checkInvalidSlideStatus(1, slide1, invalidSlideValues)
                  : pageNumber === 1
                    ? `active`
                    : slideStatus.slide1 === "complete"
                      ? `completeContent`
                      : ""
              }`}
            >
              Start
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 2)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(2)
                    ? checkInvalidSlideStatus(2, slide2, invalidSlideValues)
                    : pageNumber !== 2 && slideStatus.slide2 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 2 && slideStatus.slide2 === "complete"
                        ? "complete"
                        : pageNumber === 2
                          ? "activeDot"
                          : `incomplete`
                }
                variant={
                  pageNumber === 2 ||
                  slideStatus.slide2 === "complete" ||
                  slideStatus.slide2 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                classes={{
                  root: "timelineConnector",
                }}
                className={
                  requestStatus === "RV" && invalidSlides.includes(2)
                    ? checkInvalidSlideStatus(2, slide2, invalidSlideValues)
                    : slideStatus.slide2 === "complete"
                      ? `completedLine`
                      : slideStatus.slide2 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(2)
                  ? checkInvalidSlideStatus(2, slide2, invalidSlideValues)
                  : pageNumber === 2
                    ? "active"
                    : pageNumber !== 2 && slideStatus.slide2 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 2 && slideStatus.slide2 === "complete"
                        ? "completeContent"
                        : pageNumber !== 2 &&
                            slideStatus.slide2 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              SWPPP Services
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 3)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(3)
                    ? checkInvalidSlideStatus(3, slide3, invalidSlideValues)
                    : pageNumber !== 3 && slideStatus.slide3 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 3 && slideStatus.slide3 === "complete"
                        ? "complete"
                        : pageNumber !== 3 &&
                            slideStatus.slide3 === "incomplete"
                          ? `incomplete`
                          : pageNumber === 3
                            ? "activeDot"
                            : ""
                }
                variant={
                  pageNumber === 3 ||
                  slideStatus.slide3 === "complete" ||
                  slideStatus.slide3 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(3)
                    ? checkInvalidSlideStatus(3, slide3, invalidSlideValues)
                    : slideStatus.slide3 === "complete"
                      ? `completedLine`
                      : slideStatus.slide3 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(3)
                  ? checkInvalidSlideStatus(3, slide3, invalidSlideValues)
                  : pageNumber === 3
                    ? "active"
                    : pageNumber !== 3 && slideStatus.slide3 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 3 && slideStatus.slide3 === "complete"
                        ? "completeContent"
                        : pageNumber !== 3 &&
                            slideStatus.slide3 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              Dust Services
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 4, invalidSlideValues)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(4)
                    ? checkInvalidSlideStatus(4, slide4, invalidSlideValues)
                    : pageNumber !== 4 && slideStatus.slide4 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 4 && slideStatus.slide4 === "complete"
                        ? "complete"
                        : pageNumber !== 4 &&
                            slideStatus.slide4 === "incomplete"
                          ? `incomplete`
                          : pageNumber === 4
                            ? "activeDot"
                            : ""
                }
                variant={
                  pageNumber === 4 ||
                  slideStatus.slide4 === "complete" ||
                  slideStatus.slide4 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(4)
                    ? checkInvalidSlideStatus(4, slide4, invalidSlideValues)
                    : slideStatus.slide4 === "complete"
                      ? `completedLine`
                      : slideStatus.slide4 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(4)
                  ? checkInvalidSlideStatus(4, slide4, invalidSlideValues)
                  : pageNumber === 4
                    ? "active"
                    : pageNumber !== 4 && slideStatus.slide4 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 4 && slideStatus.slide4 === "complete"
                        ? "completeContent"
                        : pageNumber !== 4 &&
                            slideStatus.slide4 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              Project Type
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 5)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(5)
                    ? checkInvalidSlideStatus(5, slide5, invalidSlideValues)
                    : pageNumber !== 5 && slideStatus.slide5 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 5 && slideStatus.slide5 === "complete"
                        ? "complete"
                        : pageNumber !== 5 &&
                            slideStatus.slide5 === "incomplete"
                          ? `incomplete`
                          : pageNumber === 5
                            ? "activeDot"
                            : ""
                }
                variant={
                  pageNumber === 5 ||
                  slideStatus.slide5 === "complete" ||
                  slideStatus.slide5 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                // color="primary"
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(5)
                    ? checkInvalidSlideStatus(5, slide5, invalidSlideValues)
                    : slideStatus.slide5 === "complete"
                      ? `completedLine`
                      : slideStatus.slide5 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(5)
                  ? checkInvalidSlideStatus(5, slide5, invalidSlideValues)
                  : pageNumber === 5
                    ? "active"
                    : pageNumber !== 5 && slideStatus.slide5 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 5 && slideStatus.slide5 === "complete"
                        ? "completeContent"
                        : pageNumber !== 5 &&
                            slideStatus.slide5 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              Project Info.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 6)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(6)
                    ? checkInvalidSlideStatus(6, slide6, invalidSlideValues)
                    : pageNumber !== 6 && slideStatus.slide6 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 6 && slideStatus.slide6 === "complete"
                        ? "complete"
                        : pageNumber !== 6 &&
                            slideStatus.slide6 === "incomplete"
                          ? `incomplete`
                          : ""
                }
                variant={
                  pageNumber === 6 ||
                  slideStatus.slide6 === "complete" ||
                  slideStatus.slide6 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(6)
                    ? checkInvalidSlideStatus(6, slide6, invalidSlideValues)
                    : slideStatus.slide6 === "complete"
                      ? `completedLine`
                      : slideStatus.slide6 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(6)
                  ? checkInvalidSlideStatus(6, slide6, invalidSlideValues)
                  : pageNumber === 6
                    ? "active"
                    : pageNumber !== 6 && slideStatus.slide6 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 6 && slideStatus.slide6 === "complete"
                        ? "completeContent"
                        : pageNumber !== 6 &&
                            slideStatus.slide6 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              Project Location
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 7)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(7)
                    ? checkInvalidSlideStatus(7, slide7, invalidSlideValues)
                    : pageNumber !== 7 && slideStatus.slide7 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 7 && slideStatus.slide7 === "complete"
                        ? "complete"
                        : pageNumber !== 7 &&
                            slideStatus.slide7 === "incomplete"
                          ? `incomplete`
                          : ""
                }
                variant={
                  pageNumber === 7 ||
                  slideStatus.slide7 === "complete" ||
                  slideStatus.slide7 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(7)
                    ? checkInvalidSlideStatus(7, slide7, invalidSlideValues)
                    : slideStatus.slide7 === "complete"
                      ? `completedLine`
                      : slideStatus.slide7 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(7)
                  ? checkInvalidSlideStatus(7, slide7, invalidSlideValues)
                  : pageNumber === 7
                    ? "active"
                    : pageNumber !== 7 && slideStatus.slide7 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 7 && slideStatus.slide7 === "complete"
                        ? "completeContent"
                        : pageNumber !== 7 &&
                            slideStatus.slide7 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              {"Owning Entity Info."}
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 8)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(8)
                    ? checkInvalidSlideStatus(8, slide8, invalidSlideValues)
                    : pageNumber !== 8 && slideStatus.slide8 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 8 && slideStatus.slide8 === "complete"
                        ? "complete"
                        : pageNumber !== 8 &&
                            slideStatus.slide8 === "incomplete"
                          ? `incomplete`
                          : ""
                }
                variant={
                  pageNumber === 8 ||
                  slideStatus.slide8 === "complete" ||
                  slideStatus.slide8 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(8)
                    ? checkInvalidSlideStatus(8, slide8, invalidSlideValues)
                    : slideStatus.slide8 === "complete"
                      ? `completedLine`
                      : slideStatus.slide8 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(8)
                  ? checkInvalidSlideStatus(8, slide8, invalidSlideValues)
                  : pageNumber === 8
                    ? "active"
                    : pageNumber !== 8 && slideStatus.slide8 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 8 && slideStatus.slide8 === "complete"
                        ? "completeContent"
                        : pageNumber !== 8 &&
                            slideStatus.slide8 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              GC Info.
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 9)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(9)
                    ? checkInvalidSlideStatus(9, slide9, invalidSlideValues)
                    : pageNumber !== 9 && slideStatus.slide9 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 9 && slideStatus.slide9 === "complete"
                        ? "complete"
                        : pageNumber !== 9 &&
                            slideStatus.slide9 === "incomplete"
                          ? `incomplete`
                          : ""
                }
                variant={
                  pageNumber === 9 ||
                  slideStatus.slide9 === "complete" ||
                  slideStatus.slide9 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
              <TimelineConnector
                className={
                  requestStatus === "RV" && invalidSlides.includes(9)
                    ? checkInvalidSlideStatus(9, slide9, invalidSlideValues)
                    : slideStatus.slide9 === "complete" &&
                        slideStatus.slide10 === "complete"
                      ? `completedLine`
                      : slideStatus.slide9 === "incomplete"
                        ? `incompleteLine`
                        : `incompleteLine`
                }
                classes={{
                  root: "timelineConnector",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(9)
                  ? checkInvalidSlideStatus(9, slide9, invalidSlideValues)
                  : pageNumber === 9
                    ? "active"
                    : pageNumber !== 9 && slideStatus.slide9 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 9 && slideStatus.slide9 === "complete"
                        ? "completeContent"
                        : pageNumber !== 9 &&
                            slideStatus.slide9 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              Files
            </TimelineContent>
          </TimelineItem>
          <TimelineItem
            classes={{ root: "timelineLineItem" }}
            onClick={(e) => handleTimelineClick(e, 10)}
          >
            <TimelineSeparator classes={{ root: "timelineSeparator" }}>
              <TimelineDot
                className={
                  requestStatus === "RV" && invalidSlides.includes(10)
                    ? checkInvalidSlideStatus(10, slide10, invalidSlideValues)
                    : pageNumber !== 10 && slideStatus.slide10 === "incomplete"
                      ? `incomplete`
                      : pageNumber !== 10 && slideStatus.slide10 === "complete"
                        ? "complete"
                        : pageNumber !== 10 &&
                            slideStatus.slide10 === "incomplete"
                          ? `incomplete`
                          : ""
                }
                variant={
                  pageNumber === 10 ||
                  slideStatus.slide10 === "complete" ||
                  slideStatus.slide10 === "incomplete"
                    ? "filled"
                    : "outlined"
                }
                classes={{
                  root: "timelineDot",
                  outlined: "timelineDotOutlined",
                  filled: "timelineDotFilled",
                }}
              />
            </TimelineSeparator>
            <TimelineContent
              className={`timelineContent ${
                requestStatus === "RV" && invalidSlides.includes(10)
                  ? checkInvalidSlideStatus(10, slide10, invalidSlideValues)
                  : pageNumber === 10
                    ? "active"
                    : pageNumber !== 10 && slideStatus.slide10 === "incomplete"
                      ? "incompleteContent"
                      : pageNumber !== 10 && slideStatus.slide10 === "complete"
                        ? "completeContent"
                        : pageNumber !== 10 &&
                            slideStatus.slide10 === "incomplete"
                          ? "incompleteContent"
                          : ""
              }`}
            >
              Submit
            </TimelineContent>
          </TimelineItem>
        </Timeline>
        <ColorsLegend requestStatus={requestStatus} />
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default TimelineContainer;
