import _ from "lodash";

// Used for token handleing
export const getSearchParams = (location) => {
  return new URLSearchParams(location.search);
};

// Useed to populate each slide state, timestamp, and the page number [Form Container 327]
export const populateFormsState = (
  clientId,
  projectId,
  projectData,
  slideStates,
  setSlideStates,
  setTimeStamp,
  pageNumber,
  setPageNumber,
  navigate,
) => {
  const [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
  ] = slideStates;
  const [
    setSlide1,
    setSlide2,
    setSlide3,
    setSlide4,
    setSlide5,
    setSlide6,
    setSlide7,
    setSlide8,
    setSlide9,
    setSlide10,
  ] = setSlideStates;

  setTimeStamp(() => projectData.timeStamp);

  if (!_.isEqual(slide1, projectData.slide1)) {
    setSlide1(() => projectData.slide1);
  }
  if (!_.isEqual(slide2, projectData.slide2)) {
    setSlide2(() => projectData.slide2);
  }
  if (!_.isEqual(slide3, projectData.slide3)) {
    setSlide3(() => projectData.slide3);
  }
  if (!_.isEqual(slide4, projectData.slide4)) {
    setSlide4(() => projectData.slide4);
  }
  if (!_.isEqual(slide5, projectData.slide5)) {
    setSlide5(() => projectData.slide5);
  }
  if (!_.isEqual(slide6, projectData.slide6)) {
    setSlide6(() => projectData.slide6);
  }
  if (!_.isEqual(slide7, projectData.slide7)) {
    setSlide7(() => projectData.slide7);
  }
  if (!_.isEqual(slide8, projectData.slide8)) {
    setSlide8(() => projectData.slide8);
  }
  if (!_.isEqual(slide9, projectData.slide9)) {
    setSlide9((prev) => {
      return {
        ...prev,
        ...projectData.slide9,
      };
    });
  }
  if (!_.isEqual(slide10, projectData.slide10)) {
    setSlide10(() => projectData.slide10);
  }

  // If the pageNumber is not equal to the recentSlide, set the pageNumber to the recentSlide
  if (pageNumber !== 0 && pageNumber !== +projectData.recentSlide) {
    navigate(
      `/dashboard/form/${projectData.clientId}/${projectData.id}/${pageNumber}`,
    );
  } else if (pageNumber === 0 && pageNumber !== +projectData.recentSlide) {
    setPageNumber(() => +projectData.recentSlide);
    navigate(
      `/dashboard/form/${projectData.clientId}/${
        projectData.id
      }/${+projectData.recentSlide}`,
    );
  }
};

// ~ Used to update the webform to match database [Form Container 298]
export const updateFormSlidesStatus = async (
  clientId,
  projectId,
  userId,
  currentProjectData,
  pageNumber,
  requestStatus,
  setRequestStatus,
  updateFormStatus,
  setDisableAll,
  activeUserId,
  setActiveUserId,
  queryClient,
  navigate,
) => {
  let currentStatus = currentProjectData.requestStatus;
  let currentActiveUserId = currentProjectData.activeUserId;

  // If projects status is "I", enable all inputs
  if (currentStatus === "I") {
    setRequestStatus(() => "I");
    setActiveUserId(() => null);
    setDisableAll(() => false);
  } else if (currentStatus === "IP") {
    // If projects status is "IP" and there activeUser is not equal user, disable all inputs
    if (currentActiveUserId !== userId) {
      setRequestStatus(() => "IP");
      setActiveUserId(() => currentActiveUserId);
      setDisableAll(() => true);
    } else if (currentActiveUserId === userId) {
      // If projects status is "IP" and there activeUser is equal user, enable all inputs
      setRequestStatus(() => "IP");
      setActiveUserId(() => userId);
      setDisableAll(() => false);
    } else {
      // If projects status is "IP" and there is no activeUser, update the status to "I"
      updateFormStatus.mutate("I", userId);
      setDisableAll(() => false);
      setRequestStatus(() => "I");
      setActiveUserId(() => null);
    }
  } else if (currentStatus === "RV") {
    // If projects status is "RV", set the status to "RV"
    setRequestStatus(() => "RV");
    setDisableAll(() => false);
    setActiveUserId(() => null);
  } else {
    // If projects status is not "I", "IP", or "RV", disable all inputs
    setDisableAll(() => true);
    setRequestStatus(() => currentStatus);
  }

  // if pageNumber isn't 0 and project is not null, update the url to match the current slide
  if (pageNumber > 0) {
    navigate(`/dashboard/form/${clientId}/${projectId}/${pageNumber}`, {
      replace: true,
    });
  }
};

export const setReviseStatusPageNumber = (
  invalidSlideNumbers,
  invalidInputs,
  projectData,
  pageNumber,
  setPageNumber,
  navigate,
) => {
  let lowestSlideNumber = +invalidSlideNumbers[0];
  for (const invalidSlide of invalidSlideNumbers) {
    let validity = checkSlideValidity(
      invalidSlide,
      invalidSlideNumbers,
      invalidInputs,
      projectData,
    );
    if (validity === "invalid" && invalidSlide < lowestSlideNumber) {
      lowestSlideNumber = +invalidSlide;
    }
  }

  if (pageNumber !== 0 && pageNumber !== lowestSlideNumber) {
    navigate(
      `/dashboard/form/${projectData.clientId}/${projectData.id}/${pageNumber}`,
    );
  } else if (pageNumber === 0 && pageNumber !== lowestSlideNumber) {
    setPageNumber(() => lowestSlideNumber);
    navigate(
      `/dashboard/form/${projectData.clientId}/${projectData.id}/${lowestSlideNumber}`,
    );
  }
};

// | SLIDES VALIDITY HELPERS |
export const checkSlideValidity = (
  slideNumber,
  invalidSlideNumbers,
  invalidInputs,
  formState,
) => {
  let invalidSlideStatus = "valid";
  // If slideNumber is not in invalidSlideNumbers, return {[slideNumber] : 'valid'}
  if (!invalidSlideNumbers.includes(slideNumber)) {
    return invalidSlideStatus;
  }

  let slideStr = `slide${slideNumber}`;
  let slideState = formState[slideStr];

  // filter invalid inputs by slide number
  let slideInvalidInputs = invalidInputs.filter(
    (input) => input.slide === slideNumber,
  );

  // iterate over the filtered invalid inputs and check if the slide state matches the initial value
  for (const obj of slideInvalidInputs) {
    let inputName = obj.name;
    let inputVal = obj.initialValue;
    if (inputVal === "true") {
      inputVal = true;
    } else if (inputVal === "false") {
      inputVal = false;
    }
    if (slideState[inputName] === inputVal) {
      invalidSlideStatus = "invalid";
      break;
    }
  }

  return invalidSlideStatus;
};

// Used to get the current status of each slide in the webform [Form Container 92, 399, 421]
export const getAllSlidesStatus = (
  states,
  setSlideStatus,
  currentProjectData,
  userId,
) => {
  const {
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
  } = states;

  for (let i = 1; i < 11; i++) {
    if (i === 1 && slide1) {
      getSlideStatus(i, slide1, setSlideStatus, currentProjectData, userId);
    } else if (i === 2 && slide2) {
      getSlideStatus(i, slide2, setSlideStatus, currentProjectData, userId);
    } else if (i === 3 && slide3) {
      getSlideStatus(i, slide3, setSlideStatus, currentProjectData, userId);
    } else if (i === 4 && slide4) {
      getSlideStatus(i, slide4, setSlideStatus, currentProjectData, userId);
    } else if (i === 5 && slide5) {
      getSlideStatus(i, slide5, setSlideStatus, currentProjectData, userId);
    } else if (i === 6 && slide6) {
      getSlideStatus(i, slide6, setSlideStatus, currentProjectData, userId);
    } else if (i === 7 && slide7) {
      getSlideStatus(i, slide7, setSlideStatus, currentProjectData, userId);
    } else if (i === 8 && slide8) {
      getSlideStatus(i, slide8, setSlideStatus, currentProjectData, userId);
    } else if (i === 9 && slide9) {
      getSlideStatus(i, slide9, setSlideStatus, currentProjectData, userId);
    } else if (i === 10 && slide10) {
      getSlideStatus(i, slide10, setSlideStatus, currentProjectData, userId);
    }
  }
};

// Gets and sets the status of each slide
export const getSlideStatus = (
  pageNumber,
  slideState,
  setSlideStatus,
  formData,
  userId,
) => {
  if (pageNumber === 1) {
    const {
      projectName,
      pointOfContact,
      pointOfContactPhone,
      pointOfContactEmail,
      pointOfContactTitle,
      totAcr,
      distAcr,
    } = slideState;

    if (
      projectName &&
      pointOfContact &&
      pointOfContactPhone &&
      pointOfContactEmail &&
      pointOfContactTitle &&
      totAcr &&
      distAcr
    ) {
      setSlideStatus((prev) => ({ ...prev, slide1: "complete" }));
    } else {
      setSlideStatus((prev) => ({ ...prev, slide1: "incomplete" }));
    }
  } else if (pageNumber === 2) {
    const {
      sNum,
      submittingToRegAgency,
      regAgencySubmitDate,
      activeCDX,
      cdxAccountManager,
      cdxUserId,
      inspectionRequest,
      inspectionRecipient1,
      inspectionRecipient1Email,
      inspectionRecipient1Title,
      inspectingCompany,
      inspectorName,
      inspectorPhone,
      inspectorEmail,
      signRequest,
      sign3x3,
      signStand,
      signBanner,
    } = slideState;

    if (
      +sNum >= 1 &&
      ((signRequest && (sign3x3 || signStand || signBanner)) || !signRequest) &&
      ((inspectionRequest &&
        inspectionRecipient1 &&
        inspectionRecipient1Email &&
        inspectionRecipient1Title) ||
        (!inspectionRequest &&
          inspectingCompany &&
          inspectorName &&
          inspectorPhone &&
          inspectorEmail)) &&
      (!submittingToRegAgency ||
        (submittingToRegAgency && regAgencySubmitDate)) &&
      (!activeCDX || (activeCDX && cdxAccountManager && cdxUserId))
    ) {
      setSlideStatus((prev) => ({ ...prev, slide2: "complete" }));
    } else {
      setSlideStatus((prev) => ({ ...prev, slide2: "incomplete" }));
    }
  } else if (pageNumber === 3) {
    const {
      dustControlRequest,
      manualLogsRequest,
      dustAuditRequest,
      dustSignRequest,
      dcName,
      dcPhone,
      dcc,
      dccPhone,
      dccTitle,
      dustAuditContact,
      dustAuditContactEmail,
    } = slideState;

    if (dustControlRequest) {
      if (!manualLogsRequest && !dustAuditRequest && !dustSignRequest) {
        setSlideStatus((prev) => ({ ...prev, slide3: "incomplete" }));
      } else if (
        ((manualLogsRequest && dcc && dccPhone && dccTitle) ||
          !manualLogsRequest) &&
        ((dustAuditRequest && dustAuditContact && dustAuditContactEmail) ||
          !dustAuditRequest) &&
        ((dustSignRequest && dcName && dcPhone) || !dustSignRequest)
      ) {
        setSlideStatus((prev) => ({ ...prev, slide3: "complete" }));
      } else if (manualLogsRequest || dustAuditRequest || dustSignRequest) {
        setSlideStatus((prev) => ({ ...prev, slide3: "incomplete" }));
      }
    } else {
      setSlideStatus((prev) => ({ ...prev, slide3: "complete" }));
    }
  } else if (pageNumber === 4) {
    const {
      t1,
      t2,
      t3,
      t4,
      t5,
      t6,
      t7,
      t8,
      t9,
      t10,
      t11,
      t12,
      t13,
      other,
      otherText,
      scopeOfWork,
    } = slideState;

    if (
      !t1 &&
      !t2 &&
      !t3 &&
      !t4 &&
      !t5 &&
      !t6 &&
      !t7 &&
      !t8 &&
      !t9 &&
      !t10 &&
      !t11 &&
      !t12 &&
      !t13 &&
      !other &&
      scopeOfWork === ""
    ) {
      setSlideStatus((prev) => ({ ...prev, slide4: "incomplete" }));
    } else if (
      (t1 ||
        t2 ||
        t3 ||
        t4 ||
        t5 ||
        t6 ||
        t7 ||
        t8 ||
        t9 ||
        t10 ||
        t11 ||
        t12 ||
        t13 ||
        (other && otherText && otherText.length > 3)) &&
      scopeOfWork &&
      scopeOfWork.length >= 3
    ) {
      setSlideStatus((prev) => ({ ...prev, slide4: "complete" }));
    } else if (
      t1 ||
      t2 ||
      t3 ||
      t4 ||
      t5 ||
      t6 ||
      t7 ||
      t8 ||
      t9 ||
      t10 ||
      t11 ||
      t12 ||
      t13 ||
      other ||
      scopeOfWork
    ) {
      setSlideStatus((prev) => ({ ...prev, slide4: "incomplete" }));
    }
  } else if (pageNumber === 5) {
    const {
      startDate,
      endDate,
      emergencyContactName,
      emergencyContactPhone,
      emergencyContactEmail,
      emergencyContactTitle,
      portableTanksOnSite,
      portableTankCapacity,
      bmpCompanyName,
      bmpContactName,
      bmpContactPhone,
      bmpContactEmail,
    } = slideState;

    if (
      startDate &&
      endDate &&
      emergencyContactName &&
      emergencyContactPhone &&
      emergencyContactEmail &&
      emergencyContactTitle &&
      ((portableTanksOnSite && +portableTankCapacity) ||
        !portableTanksOnSite) &&
      bmpCompanyName &&
      bmpContactName &&
      bmpContactPhone &&
      bmpContactEmail
    ) {
      setSlideStatus((prev) => ({ ...prev, slide5: "complete" }));
    } else {
      setSlideStatus((prev) => ({ ...prev, slide5: "incomplete" }));
    }
  } else if (pageNumber === 6) {
    const { pAdd, pCity, pZip, pCounty, bLat, bLng, eLat, eLng, isLinear } =
      slideState;

    if (isLinear) {
      if (bLat && bLng && eLat && eLng && pAdd && pCity && pZip && pCounty) {
        setSlideStatus((prev) => ({ ...prev, slide6: "complete" }));
      } else {
        setSlideStatus((prev) => ({ ...prev, slide6: "incomplete" }));
      }
    } else if (!isLinear) {
      if (bLat && bLng && pAdd && pCity && pZip && pCounty) {
        setSlideStatus((prev) => ({ ...prev, slide6: "complete" }));
      } else {
        setSlideStatus((prev) => ({ ...prev, slide6: "incomplete" }));
      }
    }
  } else if (pageNumber === 7) {
    const {
      oName,
      oPhone,
      oCity,
      oZip,
      oAdd,
      oContactName,
      oContactPhone,
      oContactEmail,
      oContactTitle,
      delegatedRepCheck,
      odrCompany,
      odrName,
      odrPhone,
      odrEmail,
      odrTitle,
      wsNumber,
      waterServicesContact,
      waterServicesPhone,
      waterServicesEmail,
    } = slideState;

    if (formData && +formData["slide1"]["totAcr"] > 1) {
      if (
        (oName &&
          oPhone &&
          oCity &&
          oZip &&
          oAdd &&
          oContactName &&
          oContactPhone &&
          oContactEmail &&
          oContactTitle &&
          delegatedRepCheck &&
          odrCompany &&
          odrName &&
          odrPhone &&
          odrEmail &&
          odrTitle) ||
        (oName &&
          oPhone &&
          oCity &&
          oZip &&
          oAdd &&
          oContactName &&
          oContactPhone &&
          oContactEmail &&
          oContactTitle &&
          !delegatedRepCheck)
      ) {
        setSlideStatus((prev) => ({ ...prev, slide7: "complete" }));
      } else {
        setSlideStatus((prev) => ({ ...prev, slide7: "incomplete" }));
      }
    } else if (formData && +formData["slide1"]["totAcr"] < 1) {
      if (
        oName &&
        oPhone &&
        oCity &&
        oZip &&
        oAdd &&
        wsNumber &&
        waterServicesContact &&
        waterServicesPhone &&
        waterServicesEmail
      ) {
        setSlideStatus((prev) => ({ ...prev, slide7: "complete" }));
      } else {
        setSlideStatus((prev) => ({ ...prev, slide7: "incomplete" }));
      }
    }
  } else if (pageNumber === 8) {
    const {
      gcName,
      gcAdd,
      gcCity,
      gcZip,
      gcPhone,
      gcContactName,
      gcContactPhone,
      gcContactEmail,
      gcContactTitle,
      gcContact2Name,
      gcContact2Phone,
      gcContact2Email,
      gcContact2Title,
      projectManager,
      projectManagerPhone,
      projectManagerEmail,
      superintendent,
      superintendentPhone,
      superintendentEmail,
    } = slideState;

    if (formData && +formData["slide1"]["totAcr"] > 1) {
      if (
        gcName &&
        gcAdd &&
        gcCity &&
        gcZip &&
        gcPhone &&
        gcContactName &&
        gcContactPhone &&
        gcContactEmail &&
        gcContactTitle &&
        gcContact2Name &&
        gcContact2Phone &&
        gcContact2Email &&
        gcContact2Title
      ) {
        setSlideStatus((prev) => ({ ...prev, slide8: "complete" }));
      } else {
        setSlideStatus((prev) => ({ ...prev, slide8: "incomplete" }));
      }
    } else if (formData && +formData["slide1"]["totAcr"] < 1) {
      if (
        gcName &&
        gcAdd &&
        gcCity &&
        gcZip &&
        gcPhone &&
        projectManager &&
        projectManagerPhone &&
        projectManagerEmail &&
        superintendent &&
        superintendentPhone &&
        superintendentEmail
      ) {
        setSlideStatus((prev) => ({ ...prev, slide8: "complete" }));
      } else {
        setSlideStatus((prev) => ({ ...prev, slide8: "incomplete" }));
      }
    }
  } else if (pageNumber === 9) {
    const files = [];

    for (const file in slideState) {
      if (typeof slideState[file] !== "string") {
        files.push(slideState[file]);
      }
    }

    // filter files where files.status === 'Attached'
    const attachedFiles = files.filter(
      (file) => file.fileStatus === "Attached",
    );

    if (attachedFiles.length === 0) {
      setSlideStatus((prev) => ({ ...prev, slide9: "incomplete" }));
    } else if (attachedFiles.length > 0) {
      // check that all are either File or String
      const allFilesHaveFileOrString = attachedFiles.every((file) => {
        return typeof file.file === "string" || file.file instanceof File;
      });

      if (allFilesHaveFileOrString) {
        setSlideStatus((prev) => ({ ...prev, slide9: "complete" }));
      }
    }
  } else if (pageNumber === 10) {
    const { submitter, submitterPhone, submitterEmail } = slideState;

    if (userId === "argus_admin") {
      setSlideStatus((prev) => ({ ...prev, slide10: "complete" }));
      return;
    }

    if (submitter && submitterPhone && submitterEmail) {
      setSlideStatus((prev) => ({ ...prev, slide10: "complete" }));
    } else {
      setSlideStatus((prev) => ({ ...prev, slide10: "incomplete" }));
    }
  } else {
    return;
  }
};

// | General Helpers |

// Used to adjust the state field for individuals with names
export function adjustContactNameId(id) {
  const idsWithNames = [
    "inspector",
    "dc",
    "emergencyContact",
    "bmpContact",
    "odr",
    "oContact",
    "gcContact",
    "gcContact2",
    "gcContact3",
  ];
  if (idsWithNames.includes(id)) return `${id}Name`;
  if (id === "waterServices") return `${id}Contact`;

  return id;
}

// | INPUT HELPERS |
// Used to populate the placeholder for an input field
export function handleKeyDown(
  e,
  idProp,
  state,
  setState,
  autoData,
  placeholder,
  debouncedSave,
  setIsLoading,
  setPlaceholder,
  setShowPlaceholder,
  setOpen,
  setOpenHelper,
) {
  let newState;
  if (
    autoData &&
    (e.key === "Tab" || e.key === "ArrowRight") &&
    placeholder !== ""
  ) {
    e.preventDefault();
    newState = { ...state, [idProp]: placeholder };
    setState(newState);
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
    setPlaceholder("");
    setShowPlaceholder(false);
    setOpen(false);
    setOpenHelper(false);

    const currentInput = document.getElementsByClassName(`${idProp}-input`)[0];

    currentInput.blur();
  }
}

// | Number Event Handler |
export function updateNumber(
  idProp,
  value,
  state,
  setState,
  debouncedSave,
  setIsLoading,
) {
  let newState = {};
  if (idProp === "sNum") {
    newState = { ...state, [idProp]: value.toString() };
    setState(newState);
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
    return;
  }
  let num = value.replace(/[^0-9.]/g, "");

  if (typeof acr === "number") {
    num = num.toString();
  }

  newState = { ...state, [idProp]: num };
  setState(newState);

  if (debouncedSave && setIsLoading) {
    setIsLoading(true);
    debouncedSave.mutate(newState);
  }

  return;
}

// | Phone Numbers Helpers |
export function locatedPhoneNumber(formState, name, nameId) {
  const namePhoneKey = {
    pointOfContact: ["pointOfContactPhone", "1"],
    inspectorName: ["inspectorPhone", "2"],
    dcName: ["dcPhone", "3"],
    dcc: ["dccPhone", "3"],
    dustAuditContact: ["dustAuditContactPhone", "3"],
    emergencyContactName: ["emergencyContactPhone", "5"],
    bmpContactName: ["bmpContactPhone", "5"],
    oContactName: ["oContactPhone", "7"],
    odrName: ["odrPhone", "7"],
    gcContactName: ["gcContactPhone", "8"],
    gcContact2Name: ["gcContact2Phone", "8"],
    gcContact3Name: ["gcContact3Phone", "8"],
    projectManager: ["projectManagerPhone", "8"],
    superintendent: ["superintendentPhone", "8"],
    waterServicesContact: ["waterServicesPhone", "8"],
    submitter: ["submitterPhone", "10"],
  };

  let suggestedPhone = undefined;
  // Iterate over each key where key starts with "slide"
  for (const nameProp in namePhoneKey) {
    if (nameProp === nameId) continue;
    let slideStr = `slide${namePhoneKey[nameProp][1]}`;
    let slideState = formState[slideStr];
    let phoneProp = namePhoneKey[nameProp][0];

    if (slideState[nameProp] === name && slideState[phoneProp] !== "") {
      suggestedPhone = slideState[phoneProp];
      break;
    }
  }
  // If not, return undefined
  return suggestedPhone;
}

// | TITLE Helpers |
export function locatedTitle(formState, name, nameId) {
  const nameTitleKey = {
    pointOfContact: ["pointOfContactTitle", "1"],
    inspectionRecipient1: ["inspectionRecipient1Title", "2"],
    inspectionRecipient2: ["inspectionRecipient2Title", "2"],
    inspectionRecipient3: ["inspectionRecipient3Title", "2"],
    inspectionRecipient4: ["inspectionRecipient4Title", "2"],
    inspectorName: ["inspectorTitle", "2"],
    dcc: ["dccTitle", "3"],
    emergencyContactName: ["emergencyContactTitle", "5"],
    oContactName: ["oContactTitle", "7"],
    odrName: ["odrTitle", "7"],
    gcContactName: ["gcContactTitle", "8"],
    gcContact2Name: ["gcContact2Title", "8"],
    gcContact3Name: ["gcContact3Title", "8"],
    submitter: ["submitterTitle", "10"],
  };

  let suggestedTitle = undefined;

  for (const nameProp in nameTitleKey) {
    if (nameProp === nameId) continue;
    let slideStr = `slide${nameTitleKey[nameProp][1]}`;
    let slideState = formState[slideStr];
    let titleProp = nameTitleKey[nameProp][0];

    if (slideState[nameProp] === name && slideState[titleProp] !== "") {
      suggestedTitle = slideState[titleProp];
      break;
    }
  }
  // If not, return undefined
  return suggestedTitle;
}

// Show placeholder for title function
export const handleShowTitlePlaceholder = (
  idProp,
  state,
  formData,
  placeholder,
  setPlaceholder,
  setSuggestedTitle,
  showPlaceholder,
) => {
  if (showPlaceholder && state[idProp] === "") {
    const contactNameId = adjustContactNameId(idProp.slice(0, -5));
    const contactName = state[contactNameId];
    if (formData) {
      const potentialTitle = locatedTitle(formData, contactName, contactNameId);

      if (potentialTitle) {
        setPlaceholder(potentialTitle);
        setSuggestedTitle(potentialTitle);
      }
    }
  } else if (!showPlaceholder && placeholder !== "") {
    setPlaceholder("");
  } else if (
    showPlaceholder &&
    state[idProp] !== "" &&
    !placeholder.toLowerCase().startsWith(state[idProp].toLowerCase())
  ) {
    setPlaceholder("");
  }
};

// Show placeholder for phone function
export const handleShowPhonePlaceholder = (
  idProp,
  state,
  formData,
  placeholder,
  setPlaceholder,
  setSuggestedPhone,
  showPlaceholder,
) => {
  if (
    showPlaceholder &&
    state[idProp] === "" &&
    idProp !== "oPhone" &&
    idProp !== "gcPhone"
  ) {
    let contactNameId = adjustContactNameId(idProp.slice(0, -5));
    let contactName = state[contactNameId];

    if (formData) {
      let potentialPhone = locatedPhoneNumber(
        formData,
        contactName,
        contactNameId,
      );

      if (potentialPhone && !placeholder.startsWith(potentialPhone)) {
        setPlaceholder(potentialPhone);
        setSuggestedPhone(potentialPhone);
      }
    }
  } else if (showPlaceholder === false && placeholder !== "") {
    setPlaceholder("");
  } else if (
    showPlaceholder === true &&
    state[idProp] !== "" &&
    !placeholder.startsWith(state[idProp])
  ) {
    setPlaceholder("");
  }
};

// |EMAIL Helpers |
export const findMatchingEmail = (formState, name, nameId) => {
  if (!name) return;
  const nameEmailKey = {
    pointOfContact: ["pointOfContactEmail", "1"],
    inspectionRecipient1: ["inspectionRecipient1Email", "2"],
    inspectionRecipient2: ["inspectionRecipient2Email", "2"],
    inspectionRecipient3: ["inspectionRecipient3Email", "2"],
    inspectionRecipient4: ["inspectionRecipient4Email", "2"],
    inspectorName: ["inspectorEmail", "2"],
    dcc: ["dccEmail", "3"],
    emergencyContactName: ["emergencyContactEmail", "5"],
    bmpContactName: ["bmpContactEmail", "5"],
    oContactName: ["oContactEmail", "7"],
    odrName: ["odrEmail", "7"],
    waterServicesContact: ["waterServicesEmail", "7"],
    gcContactName: ["gcContactEmail", "8"],
    gcContact2Name: ["gcContact2Email", "8"],
    gcContact3Name: ["gcContact3Email", "8"],
    projectManager: ["projectManagerEmail", "8"],
    superintendent: ["superintendentEmail", "8"],
    submitter: ["submitterEmail", "10"],
  };

  let suggestedEmail = undefined;

  for (const nameProp in nameEmailKey) {
    if (nameProp === nameId) continue;
    let slideStr = `slide${nameEmailKey[nameProp][1]}`;
    let slideState = formState[slideStr];
    let emailProp = nameEmailKey[nameProp][0];

    if (slideState[nameProp] === name && slideState[emailProp] !== "") {
      suggestedEmail = slideState[emailProp];
      break;
    }
  }
  return suggestedEmail;
};

export const generateEmail = (name, domain) => {
  return name.split(" ").join("").toLowerCase() + domain;
};

export function findMostCommonDomain(emailsArr) {
  if (!emailsArr || emailsArr.length === 0) return false;
  const domainCount = {};

  emailsArr.forEach((email) => {
    const domain = `@${email.split("@")[1]}`;

    if (domainCount[domain]) {
      domainCount[domain]++;
    } else {
      domainCount[domain] = 1;
    }
  });

  let mostCommonDomain = Object.keys(domainCount).reduce((a, b) => {
    return domainCount[a] > domainCount[b] ? a : b;
  });

  return mostCommonDomain;
}

export const handleShowEmailPlaceholder = (
  idProp,
  state,
  formData,
  placeholder,
  setPlaceholder,
  setSuggestedEmail,
  showPlaceholder,
) => {
  if (showPlaceholder && state[idProp] === "") {
    const contactNameId = adjustContactNameId(idProp.slice(0, -5));
    const contactName = state[contactNameId];

    if (formData) {
      const potentialEmail = findMatchingEmail(
        formData,
        contactName,
        contactNameId,
      );
      if (potentialEmail) {
        setPlaceholder(potentialEmail);
        setSuggestedEmail(potentialEmail);
      }
    }
  } else if (!showPlaceholder && placeholder !== "") {
    setPlaceholder("");
  } else if (
    showPlaceholder &&
    state[idProp] !== "" &&
    !placeholder.startsWith(state[idProp])
  ) {
    setPlaceholder("");
  }
};

// Used to scroll to the first required field on the current slide
export const scrollToFirstRequiredField = (state) => {
  const requiredInputs = document.querySelectorAll(".required");

  // ^ IF NO REQUIRED INPUTS
  if (requiredInputs.length === 0) {
    window.scrollTo(0, 0);
    return;
  }

  const firstRequired = requiredInputs[0];
  const slideKeys = Object.keys(state);
  const textClassList = [...firstRequired.classList];

  if (textClassList.includes("date-picker")) {
    if (textClassList.includes("endDate-input")) {
      const requiredField = document.getElementsByClassName("endDate-input")[0];
      requiredField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      requiredField.focus();
    } else if (textClassList.includes("startDate-input")) {
      const requiredField =
        document.getElementsByClassName("startDate-input")[0];
      requiredField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      requiredField.focus();
    }
    return;
  }
  let textClass = "";
  // ^ IF TEXT FIELD |
  if (textClassList.includes("form-text-root")) {
    for (let i = 0; i < textClassList.length; i++) {
      textClass = textClassList[i];
      if (slideKeys.includes(textClass)) {
        textClass = `${textClass}-input`;
        break;
      }
    }
    const requiredField = document.getElementsByClassName(textClass)[0];
    requiredField.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    requiredField.focus();
    // ^ IF RADIO
  } else {
    const classList = [...firstRequired.classList];
    let radioClass = "";
    for (let i = 0; i < classList.length; i++) {
      radioClass = classList[i];
      if (slideKeys.includes(radioClass)) {
        radioClass = `${radioClass}-radio`;
        break;
      }
    }

    const requiredRadio = document.getElementsByClassName(radioClass)[0];

    requiredRadio.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
